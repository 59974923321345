import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles() {
  return appCreateStyles({
    root: {
      height: _var(CSS_VARIABLES.appHeight),
      background: 'linear-gradient(180deg, #1B1629 0%, #000 100%)',
      width: '100%',
      display: 'flex',
      color: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
    },
    block: {},
    title: {
      display: 'flex',
      justifyContent: 'center',
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAalign: 'center',
      fontSize: '24px',
      fontWeight: 300,
      lineHeight: 'normal',
    },
    wealthstack: {
      marginTop: '16px',
    },
    wealthstackImage: {},
  });
}
