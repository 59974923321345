import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '100%',
      [theme.breakpoints.down(Layout.tablet)]: {
        paddingTop: '37px',
      },
    },
    backButton: {
      position: 'absolute',
      left: '32px',
      top: '36px',
      [theme.breakpoints.down(Layout.tablet)]: {
        left: '20px',
        top: '20px',
      },
    },
    form: {
      height: '100%',
    },
  });
}
