import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '100%',
      width: '374px',
      padding: '0px 20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up(Layout.laptop)]: {
        padding: '0px',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        padding: '0px',
        width: 'auto',
        justifyContent: 'space-between',
      },
    },
    title: {
      color: '#FFF',
      textAlign: 'center',
      fontSize: '28px',
      fontWeight: 500,
      letterSpacing: '-1px',
      marginBottom: '30px',
      '&longer': {
        fontSize: '20px',
        letterSpacing: '0.4px',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        fontSize: '26px',
      },
    },
    longer: {},
    updatedDate: {
      height: '45px',
      color: '#FFF',
      width: '100%',
      backgroundColor: '#1F2A4B',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      width: '100%',
      height: '56px',
      borderRadius: '14px',
      backgroundColor: 'transparent',
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '160%',
      letterSpacing: '0.2px',
      textTransform: 'none',

      '&:hover': {
        borderWidth: '2px',
        borderColor: _var(CSS_VARIABLES.colorGrey100),
      },
      '&$active': {
        background: _var(CSS_VARIABLES.colorPrimary500),
        border: `1px solid ${_var(CSS_VARIABLES.colorSecondary100)}`,
      },
    },
    iframeBlock: {
      marginTop: '15px',
      marginBottom: '15px',
    },
    iframe: {
      height: `calc(${_var(CSS_VARIABLES.appHeight)} - 280px)`,
      width: '100%',
      [theme.breakpoints.down(Layout.laptop)]: {
        height: `calc(${_var(CSS_VARIABLES.appHeight)} - 310px)`,
      },
      maxWidth: '100%',
      '& .MuiImageList-root::-webkit-scrollbar': {
        width: '6px',
      },
      '& .MuiImageList-root::-webkit-scrollbar-thumb': {
        borderRadius: '12px',
        backgroundColor: '#5C36C2',
      },
      '& .MuiImageList-root::-webkit-scrollbar-track': {
        borderRadius: '12px',
        backgroundColor: 'transparent',
      },
      '& .MuiImageList-root': {
        scrollbarColor: '#5C36C2 #1F2A4B',
        scrollbarWidth: '6px',
      },
    },
  });
}
