import React, { useEffect, useState } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { Loading } from '@shared/components/loading';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { HttpErrorResponse } from '@shared/models/error/http-error-response';
import { AvatarImageModel } from '@shared/models/users/avatar-image';
import { UserDetailsModel } from '@shared/models/users/details-model';
import { EmptyAvatarModel } from '@shared/models/users/empty-avatar';
import { UserStatus } from '@shared/models/users/user-status';
import { isEqualArrays } from '@shared/utils/array';
import { useMutation, useQuery } from 'react-query';

import Avatars from './forms/avatars/avatars';
import Documents from './forms/documents/documents';
import PhoneNumber from './forms/phone-number/phone-number';
import SystemName from './forms/system-name/system-name';
import { UserTypeEnum } from './forms/user-type/user-type.enum';
import { OnboardingViewModel } from './onboarding.vm';

import { styles } from './onboarding.styles';

enum OnboardingFormEnum {
  PIN_CODE = 0,
  PHONE_NUMBER = 1,
  USER_TYPE = 2,
  DOCUMENTS = 4,
  SYSTEM_NAME = 5,
  AVATARS = 6,
}

export interface OnboardingProps extends AppWithStyles<typeof styles> {}

const OnboardingComponent: React.FC<OnboardingProps> = ({ classes }) => {
  const [activeForm, setActiveForm] = useState<OnboardingFormEnum>(OnboardingFormEnum.PIN_CODE);

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [documentsList, setDocumentsList] = useState<Array<string>>([]);
  const [documentsListError, setDocumentsListError] = useState<string>('');
  const [systemName, setSystemName] = useState<string>('');
  const [systemNameError, setSystemNameError] = useState<string>('');
  const [selectedAvatar, setSelectedAvatar] = useState<string | null>('not-selected');
  const [selectedAvatarError, setSelectedAvatarError] = useState<string>('');
  const [userDetails, setUserDetails] = useState<UserDetailsModel>();

  const navigate = useNavigate();

  const $vm = React.useMemo(() => new OnboardingViewModel(), []);

  useEffect(() => {
    $vm.getUserInfo().then((userDetails) => {
      switch (userDetails.asJson.userStatus) {
        case UserStatus.PreRegistered:
          setActiveForm(OnboardingFormEnum.PIN_CODE);
          savePinCodeMutation.mutate();
          break;
        case UserStatus.PinAdded:
          setActiveForm(OnboardingFormEnum.PHONE_NUMBER);
          break;
        case UserStatus.PhoneNumberAdded:
          setActiveForm(OnboardingFormEnum.USER_TYPE);
          saveUserTypeMutation.mutate({ userType: UserTypeEnum.PERSONAL });
          break;
        case UserStatus.TypeOfAccountSet:
          setActiveForm(OnboardingFormEnum.DOCUMENTS);
          break;
        case UserStatus.DocumentsApproved:
          setActiveForm(OnboardingFormEnum.SYSTEM_NAME);
          break;
        case UserStatus.SystemNameAdded:
          setActiveForm(OnboardingFormEnum.AVATARS);
          break;
        default:
          navigate(ROUTES.mobilePrivate.dashboard);
          break;
      }
    });
  }, []);

  const savePinCodeMutation = useMutation(() => $vm.savePinCode(), {
    onSuccess: () => {
      setActiveForm(OnboardingFormEnum.PHONE_NUMBER);
    },
    onError: () => {
      setActiveForm(OnboardingFormEnum.PHONE_NUMBER);
    },
  });

  const savePhoneNumberMutation = useMutation(
    (data: { phoneNumber: string }) => $vm.savePhoneNumber(data.phoneNumber),
    {
      onSuccess: () => {
        saveUserTypeMutation.mutate({ userType: UserTypeEnum.PERSONAL });
        setActiveForm(OnboardingFormEnum.DOCUMENTS);
      },
      onError: (e: HttpErrorResponse) => {
        setPhoneNumberError(e.findFirstErrorMessage());
      },
    },
  );

  const saveUserTypeMutation = useMutation(
    (data: { userType: UserTypeEnum }) => $vm.saveUserType(data.userType),
    {
      onSuccess: () => {
        setActiveForm(OnboardingFormEnum.DOCUMENTS);
      },
    },
  );

  const saveApprovedDocumentsMutation = useMutation(
    (data: { listOfIds: Array<string> }) => $vm.saveApprovedDocuments(data.listOfIds),
    {
      onSuccess: () => {
        setActiveForm(OnboardingFormEnum.SYSTEM_NAME);
      },
      onError: (e: HttpErrorResponse) => {
        setDocumentsListError(e.findFirstErrorMessage());
      },
    },
  );

  const saveSystemNameMutation = useMutation(
    (data: { systemName: string }) => $vm.saveSystemName(data.systemName),
    {
      onSuccess: () => {
        setActiveForm(OnboardingFormEnum.AVATARS);
        getMyEmptyAvatarQuery.refetch();
      },
      onError: (e: HttpErrorResponse) => {
        setSystemNameError(e.findFirstErrorMessage());
      },
    },
  );

  const getTermsDocumentQuery = useQuery(['get-terms-documents'], () => $vm.getTermsDocuments(), {
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const getAvatarsQuery = useQuery(['get-avatars'], () => $vm.getAvatarsList(), {
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const getUserInfoQuery = useQuery(
    ['get-user-order-details', activeForm],
    () => $vm.getUserInfo(),
    {
      onSuccess: (userDetails) => {
        if (userDetails.asJson.phone && phoneNumber !== userDetails.asJson.phone) {
          setPhoneNumber(userDetails.asJson.phone);
        }
        if (userDetails.asJson.systemName && systemName !== userDetails.asJson.systemName) {
          setSystemName(userDetails.asJson.systemName);
        }
        setUserDetails(userDetails);
      },
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  const getMyEmptyAvatarQuery = useQuery(['get-empty-avatar'], () => $vm.getEmptyAvatar(), {
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const saveAvatarMutation = useMutation(
    (data: { avatarId: string | null }) => $vm.saveAvatar(data.avatarId),
    {
      onSuccess: () => {
        navigate(ROUTES.welcomeScreen);
      },
      onError: (e: HttpErrorResponse) => {
        setSelectedAvatarError(e.findFirstErrorMessage());
      },
    },
  );

  const onSubmitPhoneNumberForm = (formPhoneNumber: string) => {
    if (
      userDetails?.asJson.phone !== formPhoneNumber ||
      userDetails?.asJson.userStatus === UserStatus.PinAdded
    ) {
      setPhoneNumber(formPhoneNumber);
      savePhoneNumberMutation.mutate({ phoneNumber: formPhoneNumber });
    } else {
      setActiveForm(OnboardingFormEnum.DOCUMENTS);
    }
  };

  const onSubmitTermsDocument = (listOfIds: Array<string>) => {
    if (
      !isEqualArrays(userDetails?.asJson.approvedDocumentIds as Array<string>, listOfIds) ||
      userDetails?.asJson.userStatus === UserStatus.TypeOfAccountSet
    ) {
      setDocumentsList(listOfIds);
      saveApprovedDocumentsMutation.mutate({ listOfIds });
    } else {
      setActiveForm(OnboardingFormEnum.SYSTEM_NAME);
    }
  };

  const onSubmitSystemName = (systemName: string) => {
    if (
      userDetails?.asJson.systemName !== systemName ||
      userDetails?.asJson.userStatus === UserStatus.DocumentsApproved
    ) {
      setSystemName(systemName);
      saveSystemNameMutation.mutate({ systemName });
    } else {
      setActiveForm(OnboardingFormEnum.AVATARS);
    }
  };

  const onSubmitAvatar = (avatarId: string | null) => {
    setSelectedAvatar(avatarId);
    saveAvatarMutation.mutate({ avatarId });
  };

  const resetValidationPhoneNumber = () => {
    setPhoneNumberError('');
  };

  const resetValidationDocuments = () => {
    setDocumentsListError('');
  };

  const resetValidationSystemName = () => {
    setSystemNameError('');
  };

  const resetValidationAvatar = () => {
    setSelectedAvatarError('');
  };

  const handleBackButton = () => {
    switch (activeForm) {
      case OnboardingFormEnum.DOCUMENTS:
        setActiveForm(OnboardingFormEnum.PHONE_NUMBER);
        break;
      case OnboardingFormEnum.SYSTEM_NAME:
        setActiveForm(OnboardingFormEnum.DOCUMENTS);
        break;
      case OnboardingFormEnum.AVATARS:
        setActiveForm(OnboardingFormEnum.SYSTEM_NAME);
        break;
      default:
        break;
    }
  };

  const isLoading = () => {
    return (
      getTermsDocumentQuery.isLoading ||
      getAvatarsQuery.isLoading ||
      getMyEmptyAvatarQuery.isFetching ||
      getUserInfoQuery.isLoading ||
      savePhoneNumberMutation.isLoading ||
      saveUserTypeMutation.isLoading ||
      saveSystemNameMutation.isLoading ||
      saveAvatarMutation.isLoading ||
      saveApprovedDocumentsMutation.isLoading
    );
  };

  const phoneNumberForm = (
    <PhoneNumber
      onSubmit={onSubmitPhoneNumberForm}
      defaultValue={phoneNumber}
      resetValidation={resetValidationPhoneNumber}
      error={phoneNumberError}
      isLoading={isLoading()}
    />
  );

  const documentsForm = (
    <Documents
      onSubmit={onSubmitTermsDocument}
      error={documentsListError}
      defaultValue={documentsList}
      resetValidation={resetValidationDocuments}
      documentsList={getTermsDocumentQuery.data || []}
      isLoading={isLoading()}
    />
  );

  const systemNameForm = (
    <SystemName
      onSubmit={onSubmitSystemName}
      defaultValue={systemName}
      error={systemNameError}
      resetValidation={resetValidationSystemName}
      isLoading={isLoading()}
    />
  );

  const avatarsForm = (
    <Avatars
      avatarsList={getAvatarsQuery.data as Array<AvatarImageModel>}
      myEmptyAvatar={getMyEmptyAvatarQuery.data as EmptyAvatarModel}
      onSubmit={onSubmitAvatar}
      error={selectedAvatarError}
      defaultValue={selectedAvatar}
      resetValidation={resetValidationAvatar}
      isLoading={isLoading()}
    />
  );

  let formComponent = <></>;
  switch (activeForm) {
    case OnboardingFormEnum.PHONE_NUMBER:
      formComponent = phoneNumberForm;
      break;
    case OnboardingFormEnum.DOCUMENTS:
      formComponent = documentsForm;
      break;
    case OnboardingFormEnum.SYSTEM_NAME:
      formComponent = systemNameForm;
      break;
    case OnboardingFormEnum.AVATARS:
      formComponent = avatarsForm;
      break;
    default:
      formComponent = <></>;
  }
  if (
    activeForm === OnboardingFormEnum.PIN_CODE ||
    activeForm === OnboardingFormEnum.USER_TYPE ||
    !getMyEmptyAvatarQuery.data
  ) {
    formComponent = <Loading size={70} />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.backButton}>
        {[
          OnboardingFormEnum.DOCUMENTS,
          OnboardingFormEnum.SYSTEM_NAME,
          OnboardingFormEnum.AVATARS,
        ].includes(activeForm) ? (
          <BackButton onClick={handleBackButton} />
        ) : (
          <></>
        )}
      </div>
      <div className={classes.form}>{formComponent}</div>
    </div>
  );
};

export default appWithStyles(styles)(OnboardingComponent);
