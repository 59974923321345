import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '100%',
      width: '374px',
      padding: '0px 20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down(Layout.tablet)]: {
        padding: '20px',
        justifyContent: 'space-between',
      },

      '& .MuiImageList-root::-webkit-scrollbar': {
        width: '6px',
      },
      '& .MuiImageList-root::-webkit-scrollbar-thumb': {
        borderRadius: '12px',
        backgroundColor: '#5C36C2',
      },
      '& .MuiImageList-root::-webkit-scrollbar-track': {
        borderRadius: '12px',
        backgroundColor: 'transparent',
      },
      '& .MuiImageList-root': {
        scrollbarColor: '#5C36C2 #1F2A4B',
        scrollbarWidth: '6px',
        height: '100%',
      },
    },
    title: {
      fontWeight: 500,
      fontSize: '33px',
      lineHeight: '39px',
      marginBottom: '28px',
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
    },
    description: {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '20px',
      color: _var(CSS_VARIABLES.colorGrey50),
    },
    link: {
      color: _var(CSS_VARIABLES.colorSecondary100),
    },
    fields: {
      marginTop: '38px',
      marginBottom: '45px',
      position: 'relative',
      height: 'calc(100% - 110px)',
    },
    field: {
      width: '100%',
      height: '100%',
    },
    error: {
      position: 'absolute',
      bottom: '-33px',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '165%',
      letterSpacing: '0.2px',
      marginLeft: '0px',
      marginRight: '0px',
      color: _var(CSS_VARIABLES.colorError500),
    },
    formContent: {
      height: 'calc(100% - 80px)',
      [theme.breakpoints.up(Layout.laptop)]: {
        height: 'calc(100% - 125px)',
      },
    },
  });
}
