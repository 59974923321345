import React, { useMemo } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import VerificationTopbar from '@modules/private/verification/pages/verification-topbar';
import { VerificationViewModel } from '@modules/private/verification/verification.vm';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Loading } from '@shared/components/loading';
import { PersonaWidget } from '@shared/components/new-design/persona-widget';
import { useNavigate } from '@shared/components/router';
import { useLocation } from 'react-router-dom';

import { styles } from './verification.styles';

export interface Props extends AppWithStyles<typeof styles> {}

const Verification: React.FC<Props> = appObserver(({ classes }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const redirectToTarget = () => {
    const { redirect } = state;
    if (!state || !state.redirect) {
      return navigate(-1);
    } else {
      return navigate(redirect);
    }
  };

  const redirectToBack = () => {
    return navigate(-1);
  };

  const redirectToTransaction = () => {
    navigate(-1);
  };

  const removePurchase = () => {
    localStorage.removeItem('purchase_in_progress');
  };

  const removePurchaseAndRedirectToPurchase = () => {
    removePurchase();
    redirectToTransaction();
  };

  const onCompleteKYC = () => {
    $vm.showMessage(t`You are successfully Verified`);
    redirectToTarget();
  };

  const onErrorKYC = () => {
    $vm.showMessage(t`Your verification was Declined`);
    removePurchaseAndRedirectToPurchase();
  };

  const $vm = useMemo(() => new VerificationViewModel(), []);

  // @ts-ignore
  const widget = <PersonaWidget onComplete={onCompleteKYC} onError={onErrorKYC} />;
  const widgetComponent = (
    <div className={classes.root}>
      <div className={classes.closeButton}>
        <IconButton aria-label="close" onClick={redirectToBack}>
          <Close />
        </IconButton>
      </div>
      <VerificationTopbar isVisibleTopbar={false} />
      <div className={classes.widgetContentBlock}>
        <div className={classes.content}>{widget}</div>
      </div>
    </div>
  );

  if ($vm.loading.info || $vm.loading.update) {
    return (
      <div className={classes.loading}>
        <Loading size={70} />
      </div>
    );
  }

  return widgetComponent;
});

export default appWithStyles(styles)(Verification);
