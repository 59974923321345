import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

import LogoSvg from './img/logo.svg';

export function styles({ breakpoints }: AppTheme) {
  return appCreateStyles({
    header: {
      position: 'relative',
      zIndex: 1,
      width: 'inherit',
      display: 'flex',
      padding: '0 24px 0 0',
      justifyContent: 'space-between',
      height: _var(CSS_VARIABLES.headerHeight),
      background: '#291945',
      borderBottom: `1px solid ${_var(CSS_VARIABLES.bordersMainColor)}`,

      [breakpoints.down(Layout.mobile)]: {
        position: 'fixed',
        padding: '0 12px 0 0',
        boxShadow: '0 2px 9px 5px #9f9f9f10, 8px 2px 8px 0 #9f9f9f05',
      },
    },
    burger: {
      [breakpoints.up(Layout.tablet)]: {
        display: 'none',
      },
    },
    logo: {
      overflow: 'hidden',
      display: 'block',
      boxSizing: 'border-box',
      height: '100%',
      flexBasis: '160px',
      width: '160px',
      padding: '0 24px 0 24px',
      background: `url(${LogoSvg}) no-repeat 24px center`,
      backgroundSize: 'auto 29px',
      textIndent: '-400%',
      color: 'transparent',

      [breakpoints.down(Layout.mobile)]: {
        position: 'absolute',
        left: '0',
        right: '0',
        margin: 'auto',
      },
    },
    right: {
      display: 'flex',
      alignItems: 'center',
    },
    rates: {
      background: 'transparent',
      height: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      color: '#fff',
      fontSize: '11px',
      fontWeight: 300,
      borderRadius: '24px',
      border: '1px solid #473763',
      width: '326px',
    },
    value: {
      fontWeight: 500,
    },
    switch: {
      marginLeft: '40px',
      display: 'flex',
      padding: '5px 18px 5px 8px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '9px',
      borderRadius: '34px',
      background: '#473763',
      '& .MuiToggleButton-root': {
        height: '20px',
        color: '#FFF',
        fontSize: '9px',
        fontWeight: 400,
      },
    },
    settings: {
      position: 'relative',
      margin: '0 0 0 40px',
    },
  });
}
