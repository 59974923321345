import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles() {
  return appCreateStyles({
    root: {
      '& .MuiImageListItem-img': {
        backgroundColor: _var(CSS_VARIABLES.colorPrimary500),
        display: 'flex',
        maxWidth: '98px',
        maxHeight: '98px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '12px',
        objectFit: 'contain',
      },
    },
    pointer: {
      cursor: 'pointer',
    },
    label: {
      marginTop: '12px',
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '150%',
      letterSpacing: '0.2px',
      width: '98px',
    },
    checked: {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      backgroundColor: '#00C566',
      position: 'absolute',
      bottom: '30px',
      right: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    abbreviation: {
      position: 'absolute',
      right: '18px',
      top: '22px',
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAlign: 'center',
      fontSize: '48px',
      fontWeight: 700,
      lineHeight: '120%',
      width: '98px',
    },
  });
}
