import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { UserDetailsModel } from '@shared/models/users/details-model';
import { INotificationService } from '@shared/types/notification-service';
export class VerificationViewModel {
  private notificationsService = appInject<INotificationService>(DI_TOKENS.notificationService);
  private _restrictions = {
    blockUserTransactions: true,
  };

  private _loading = {
    info: false,
    update: false,
    restrictions: false,
  };

  private _error = {
    info: false,
    update: false,
    restrictions: false,
  };

  private _info: UserDetailsModel = new UserDetailsModel({
    initials: '',
    id: '',
    name: '',
    alterName: '',
    email: '',
    userStatus: '',
    phone: '',
    citizenshipCountry: '',
    citizenshipCity: '',
    zipCode: '',
    address: '',
  });

  constructor() {
    appMakeObservable(this, {
      _loading: appObservable,
      _error: appObservable,
      _info: appObservable,
      _restrictions: appObservable,
    });
  }

  get restrictions() {
    return this._restrictions;
  }

  get loading() {
    return this._loading;
  }

  get info() {
    return this._info;
  }

  get error() {
    return this._error;
  }

  showMessage(text: string) {
    return this.notificationsService.showSuccess(text);
  }
}
