import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';
import dashboardSelected from '@modules/private/layout/components/sidebar/img/dashboard-icon-selected.svg';
import dashboard from '@modules/private/layout/components/sidebar/img/dashboard-icon.svg';
import marketSelected from '@modules/private/layout/components/sidebar/img/market-icon-selected.svg';
import market from '@modules/private/layout/components/sidebar/img/market-icon.svg';
import ordersSelected from '@modules/private/layout/components/sidebar/img/orders-icon-selected.svg';
import orders from '@modules/private/layout/components/sidebar/img/orders-icon.svg';
import settingsSelected from '@modules/private/layout/components/sidebar/img/settings-icon-selected.svg';
import settings from '@modules/private/layout/components/sidebar/img/settings-icon.svg';
import transfersSelected from '@modules/private/layout/components/sidebar/img/transfers-icon-selected.svg';
import transfers from '@modules/private/layout/components/sidebar/img/transfers-icon.svg';
import walletsSelected from '@modules/private/layout/components/sidebar/img/wallets-icon-selected.svg';
import wallets from '@modules/private/layout/components/sidebar/img/wallets-icon.svg';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles({ spacing }: AppTheme) {
  return appCreateStyles({
    minimized: {},
    selected: {},
    root: {
      '&$minimized a': {
        width: _var(CSS_VARIABLES.menuWidthMinimized),
        backgroundPosition: 'center',
        color: 'transparent',
      },

      '& li + li': {
        marginTop: '10px',
      },

      '& a': {
        overflow: 'hidden',
        display: 'block',
        position: 'relative',
        width: _var(CSS_VARIABLES.menuWidth),
        height: 48,
        padding: '14px 0 10px 61px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '25px center',
        backgroundSize: '24px 24px',
        color: '#fff',
        lineHeight: '22px',
        fontSize: '13px',
        fontWeight: 400,
        transition: 'background-position 0.3s ease-in-out, padding 0.3s ease-in-out',
        letterSpacing: '0.3px',
        textDecoration: 'none',

        '&:hover, &$selected': {
          padding: spacing(3, 0, 3, 16),
          fontWeight: 500,
        },
      },
    },
    dashboard: {
      backgroundImage: `url(${dashboard})`,
      '&$selected': {
        backgroundImage: `url(${dashboardSelected})`,
      },
    },
    market: {
      backgroundImage: `url(${market})`,
      '&$selected': {
        backgroundImage: `url(${marketSelected})`,
      },
    },
    transfers: {
      backgroundImage: `url(${transfers})`,
      '&$selected': {
        backgroundImage: `url(${transfersSelected})`,
      },
    },
    wallets: {
      backgroundImage: `url(${wallets})`,
      '&$selected': {
        backgroundImage: `url(${walletsSelected})`,
      },
    },
    orders: {
      backgroundImage: `url(${orders})`,
      '&$selected': {
        backgroundImage: `url(${ordersSelected})`,
      },
    },
    settings: {
      backgroundImage: `url(${settings})`,
      '&$selected': {
        backgroundImage: `url(${settingsSelected})`,
      },
    },
    menuTooltip: {},
  });
}
