import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

import switcherImage from './img/switcher.svg';

export function styles({ breakpoints }: AppTheme) {
  return appCreateStyles({
    root: {
      position: 'relative',
      minHeight: 'inherit',
      width: _var(CSS_VARIABLES.menuWidth),
      flexBasis: _var(CSS_VARIABLES.menuWidth),
      flexGrow: 0,
      flexShrink: 0,
      boxSizing: 'border-box',
      paddingTop: _var(CSS_VARIABLES.headerHeight),
      transition: 'width, flex-basis 0.3s ease-in-out',
      background: '#291945',

      [breakpoints.down(Layout.tablet)]: {
        display: 'none',
      },

      '&$minimized': {
        width: _var(CSS_VARIABLES.menuWidthMinimized),
        flexBasis: _var(CSS_VARIABLES.menuWidthMinimized),
      },

      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    container: {
      padding: '20px 0',
    },
    minimized: {},
    switcher: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto 0 auto auto',
      width: '31px',
      height: '63px',
      background: 'no-repeat left top',
      backgroundSize: '100%',
      backgroundImage: `url(${switcherImage})`,
      cursor: 'pointer',

      '&$minimized': {
        right: 'auto',
        left: '-1px',
        margin: 'auto auto auto 0',
        transform: 'rotate(180deg)',
      },
    },
  });
}
