import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Button } from '@mui/material';
import { getMonthName } from '@shared/utils/date';
import classNames from 'classnames';

import { styles } from './document.styles';

export interface DocumentProps extends AppWithStyles<typeof styles> {
  onAccept: (id: string) => void;
  title: string;
  date: Date;
  url: string;
  id: string;
}

const DocumentComponent: React.FC<DocumentProps> = ({
  onAccept,
  classes,
  title,
  url,
  id,
  date,
}) => {
  return (
    <div className={classes.root}>
      <div>
        <div
          className={title.length > 20 ? classNames(classes.title, classes.longer) : classes.title}
        >
          {title}
        </div>
        <div className={classes.updatedDate}>
          {t`Dated`} {getMonthName(date)} {date.getFullYear()}
        </div>
        <div className={classes.iframeBlock}>
          <iframe className={classes.iframe} src={url}></iframe>
        </div>
      </div>
      <Button variant="contained" disabled={false} onClick={() => onAccept(id)}>
        {t`Accept`}
      </Button>
    </div>
  );
};

export default appWithStyles(styles)(DocumentComponent);
