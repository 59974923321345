import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '100%',
      width: '374px',
      padding: '0px 20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up(Layout.laptop)]: {
        padding: '0px',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        padding: '20px',
        justifyContent: 'space-between',
      },

      '& .MuiButton-outlined': {
        backgroundColor: '#1E1D36 !important',
        border: 'none !important',
      },
      '& .button-number': {
        backgroundColor: '#2B2A42',
      },
      '& .MuiButton-outlined:has(div .checked)': {
        border: '1px solid #6B39F4 !important',
      },
    },
    title: {
      fontWeight: 500,
      fontSize: '33px',
      lineHeight: '39px',
      marginBottom: '28px',
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
    },
    description: {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '20px',
      color: _var(CSS_VARIABLES.colorGrey50),
      '& + &': {
        marginTop: '28px',
      },
    },
    link: {
      color: _var(CSS_VARIABLES.colorSecondary100),
    },
    fields: {
      marginTop: '50px',
      marginBottom: '73px',
      position: 'relative',
    },
    field: {
      width: '100%',
      '& + &': {
        marginTop: '16px',
      },
    },
    button: {
      width: '100%',
      height: '80px',
      border: 'none',
      borderRadius: '16px',
      backgroundColor: 'transparent',
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '160%',
      letterSpacing: '0.2px',
      textTransform: 'none',
      display: '',

      '&:hover': {
        border: 'none',
        backgroundColor: 'transparent',
      },
    },
    error: {
      position: 'absolute',
      bottom: '-26px',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '165%',
      letterSpacing: '0.2px',
      marginLeft: '0px',
      marginRight: '0px',
      color: _var(CSS_VARIABLES.colorError500),
    },
  });
}
