import { appCreateStyles } from '@core/theme/utils/create-styles';

import arrowLeftSwg from './img/arrow-left.svg';
import arrowBackSwg from './img/arrow_back.svg';

export function styles() {
  return appCreateStyles({
    root: {
      backgroundColor: '#fff',
      width: '100%',
      height: '50px',
      padding: '17px 32px',
      '@media (max-width: 499.95px)': {
        '&': {
          backgroundColor: 'inherit',
          padding: '22px 17px 14px 15px',
        },
      },
    },
    backButton: {
      width: '9px',
      height: '15px',
      background: `url(${arrowBackSwg}) no-repeat left center`,
      cursor: 'pointer',
      '@media (max-width: 499.95px)': {
        '&': {
          width: '16px',
          height: '16px',
          background: `url(${arrowLeftSwg}) no-repeat left center`,
        },
      },
    },
  });
}
