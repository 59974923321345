import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { NavLink } from '@shared/components/router';
import { AppTooltip } from '@shared/components/tooltip';
import { ROUTES } from '@shared/constants/routes';
import classNames from 'classnames';

import { styles } from './menu.styles';

export enum MenuMode {
  minimized,
  normal,
}

export interface MenuProps extends AppWithStyles<typeof styles> {
  mode: MenuMode;
  showSettings?: boolean;
}

const MenuComponent: React.FC<MenuProps> = ({ classes, mode, showSettings }) => {
  const links = [
    { label: t`Accounts`, className: classes.dashboard, path: ROUTES.desktopPrivate.dashboard },
    { label: t`Activity`, className: classes.wallets, path: ROUTES.desktopPrivate.wallets.root },
    { label: t`Orders`, className: classes.orders, path: ROUTES.desktopPrivate.orders },
    { label: t`Market`, className: classes.market, path: ROUTES.desktopPrivate.market },
  ];

  return (
    <ul
      className={classNames(classes.root, {
        [classes.minimized as string]: mode === MenuMode.minimized,
      })}
    >
      {links.map(({ label, className, path }) => (
        <AppTooltip
          title={label}
          placement={'right-start'}
          className={classes.menuTooltip}
          disableHoverListener={mode === MenuMode.normal}
        >
          <li key={label}>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${className} ${classes.selected}` : className
              }
              to={path}
            >
              <span>{label}</span>
            </NavLink>
          </li>
        </AppTooltip>
      ))}
    </ul>
  );
};

export const Menu = appWithStyles(styles)(MenuComponent);
