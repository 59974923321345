import React, { useState, KeyboardEvent } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { ImageList } from '@mui/material';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { AvatarImageModel } from '@shared/models/users/avatar-image';
import { EmptyAvatarModel } from '@shared/models/users/empty-avatar';

import { Avatar } from './components/avatar';
import { EmptyAvatar } from './components/empty-avatar';

import { styles } from './avatars.styles';

export interface AvatarsFormProps extends AppWithStyles<typeof styles> {
  onSubmit: (avatarId: string | null) => void;
  avatarsList: Array<AvatarImageModel>;
  myEmptyAvatar: EmptyAvatarModel;
  resetValidation?: () => void;
  defaultValue: string | null;
  error?: string;
  isLoading?: boolean;
}

const AvatarsFormComponent: React.FC<AvatarsFormProps> = ({
  classes,
  error,
  onSubmit,
  avatarsList,
  myEmptyAvatar,
  resetValidation,
  defaultValue,
  isLoading,
}) => {
  const [selectedAvatar, setSelectedAvatar] = useState<string | null>(defaultValue);

  const onSelectAvatar = (avatarId: string | null) => {
    resetValidation && resetValidation();
    setSelectedAvatar(avatarId);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter' && selectedAvatar !== 'not-selected') {
      onSubmit(selectedAvatar);
    }
  };

  return (
    <form
      onSubmit={() => onSubmit(selectedAvatar)}
      onKeyDown={handleKeyPress}
      className={classes.root}
    >
      <div className={classes.formContent}>
        <div className={classes.title}>{t`Choose Your Avatar`}</div>
        <div className={classes.fields}>
          <div className={classes.field}>
            <ImageList sx={{ width: 355, height: 420 }} cols={3} rowHeight={139}>
              <EmptyAvatar
                nameAbbreviation={myEmptyAvatar.nameAbbreviation}
                color={myEmptyAvatar.color}
                isActive={selectedAvatar === null}
                onSelect={onSelectAvatar}
              />
              {avatarsList.map((item, index) => {
                return (
                  <Avatar
                    id={item.id}
                    url={item.url}
                    label={`${t`Avatar`} ${index + 2}`}
                    isActive={selectedAvatar === item.id}
                    onSelect={onSelectAvatar}
                  />
                );
              })}
            </ImageList>
          </div>
          <div className={classes.error}>{error}</div>
        </div>
      </div>
      <SubmitButton
        label={t`Continue`}
        disabled={selectedAvatar === 'not-selected'}
        isLoading={isLoading}
        onSubmit={() => onSubmit(selectedAvatar)}
      />
    </form>
  );
};

export default appWithStyles(styles)(AvatarsFormComponent);
