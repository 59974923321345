import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import classNames from 'classnames';

import { Menu, MenuMode } from 'src/ui/private/layout/components/sidebar/components/menu';

import { styles } from './sidebar.styles';

export interface SidebarProps extends AppWithStyles<typeof styles> {}

const SidebarComponent: React.FC<SidebarProps> = ({ classes }) => {
  const [isMinimized, setIsMinimized] = React.useState(true);
  const handleMinimize = () => setIsMinimized(!isMinimized);
  return (
    <div className={classNames(classes.root, { [classes.minimized as string]: isMinimized })}>
      <div className={classes.container}>
        <Menu mode={isMinimized ? MenuMode.minimized : MenuMode.normal} />
      </div>
      <span
        className={classNames(classes.switcher, { [classes.minimized as string]: isMinimized })}
        onClick={handleMinimize}
      ></span>
    </div>
  );
};

export const Sidebar = appWithStyles(styles)(SidebarComponent);
