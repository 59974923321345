import { PhoneNumber } from '@core/forms/validators/phone-number';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Validate } from 'class-validator';

export interface IPhoneViewForm {
  phoneNumber: string;
}

export class PhoneViewFormFields implements IPhoneViewForm {
  @Validate(PhoneNumber)
  phoneNumber: string;
}

export const PhoneViewFieldsResolver = classValidatorResolver(PhoneViewFormFields);
