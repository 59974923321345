import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& h3': {
        color: _var(CSS_VARIABLES.colorPrimary100),
        textAlign: 'center',
        fontSize: '36px',
        fontWeight: 500,
        lineHeight: '150%',
      },
      '& p': {
        color: _var(CSS_VARIABLES.colorPrimary100),
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 500,
      },

      '& .carousel': {
        height: '764px',
        width: '578px',
      },

      '& .carousel-caption': {
        top: '0px',
      },

      '& .carousel-item img': {
        width: '530px',
        marginTop: '161px',
        marginLeft: '20px',
      },
      '& .carousel-indicators [data-bs-target]': {
        width: '63px',
      },
    },
  });
}
