import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { ImageListItem } from '@mui/material';

import CheckIconSvg from './img/check.svg';

import { styles } from './avatar.styles';

export interface AvatarProps extends AppWithStyles<typeof styles> {
  id: string;
  url: string;
  label: string;
  isActive: boolean;
  onSelect: (avatarId: string) => void;
}

const AvatarComponent: React.FC<AvatarProps> = ({
  classes,
  id,
  url,
  label,
  isActive,
  onSelect,
}) => {
  return (
    <div className={isActive ? classes.root : `${classes.root} ${classes.pointer}`}>
      <ImageListItem onClick={(e) => onSelect(id)}>
        <img src={url} alt={id} loading="lazy" />
        <div className={classes.label}>{label}</div>
        {isActive ? (
          <div className={classes.checked}>
            {' '}
            <img src={String(CheckIconSvg)} />
          </div>
        ) : (
          <></>
        )}
      </ImageListItem>
    </div>
  );
};

export const Avatar = appWithStyles(styles)(AvatarComponent);
