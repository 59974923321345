import React, { KeyboardEvent } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import {
  ISystemNameOnbardingForm,
  SystemNameOnbardingFieldsResolver,
} from '@modules/private/onboarding/forms/system-name/system-name.validator';
import { InputText } from '@shared/components/new-design/input-text';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { nameOf } from '@shared/utils/nameof';
import { useForm } from 'react-hook-form';

import { styles } from './system-name.styles';

export interface SystemNameFormProps extends AppWithStyles<typeof styles> {
  onSubmit: (systemName: string) => void;
  resetValidation?: () => void;
  defaultValue: string;
  error?: string;
  isLoading?: boolean;
}

const SystemNameFormComponent: React.FC<SystemNameFormProps> = ({
  classes,
  error,
  onSubmit,
  resetValidation,
  defaultValue,
  isLoading,
}) => {
  const {
    setError,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<ISystemNameOnbardingForm>({ resolver: SystemNameOnbardingFieldsResolver });

  const onSubmitFrom = ({ systemName }: ISystemNameOnbardingForm) => {
    onSubmit(systemName);
  };

  const resetValidationFunc = () => {
    setError(nameOf<ISystemNameOnbardingForm>('systemName'), { message: '' });
    resetValidation && resetValidation();
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter' && isValid && !Boolean(error)) {
      handleSubmit(onSubmitFrom)();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitFrom)} onKeyDown={handleKeyPress} className={classes.root}>
      <div>
        <div className={classes.title}>{t`Create your system name?`}</div>
        <div className={classes.description}>
          {t`Other Users will see you under this name. You can change this name anytime in settings.`}
        </div>
        <div className={classes.fields}>
          <div className={classes.field}>
            <InputText
              controls={register(nameOf<ISystemNameOnbardingForm>('systemName'))}
              placeholder={''}
              resetValidation={resetValidationFunc}
              autoFocus
              defaultValue={defaultValue}
              error={Boolean(error)}
              helperText={error}
              key={'systemName'}
            />
            <div className={classes.label}>{t`Name should be min 3 - max 30 letters`}</div>
          </div>
        </div>
      </div>
      <SubmitButton
        label={t`Continue`}
        disabled={!isValid || Boolean(error)}
        isLoading={isLoading}
        onSubmit={handleSubmit(onSubmitFrom)}
      />
    </form>
  );
};

export default appWithStyles(styles)(SystemNameFormComponent);
