import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { t } from '@lingui/macro';
import { MaxLength, MinLength } from 'class-validator';

export interface ISystemNameOnbardingForm {
  systemName: string;
}

export class SystemNameOnbardingFields implements ISystemNameOnbardingForm {
  @MinLength(3, { message: t`Name should be min 3 - max 30 letters` })
  @MaxLength(30, { message: t`Name should be min 3 - max 30 letters` })
  systemName: string;
}

export const SystemNameOnbardingFieldsResolver = classValidatorResolver(SystemNameOnbardingFields);
