import { FC } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Button } from '@shared/components/button';
import { Link } from '@shared/components/link';
import { Typography } from '@shared/components/typography';
import { ROUTES } from '@shared/constants/routes';

import { styles } from './welcome.styles';

export interface WelcomeProps extends AppWithStyles<typeof styles> {}

const Welcome: FC<WelcomeProps> = appObserver(({ classes }) => {
  return (
    <div className={classes.root}>
      <Typography variant="h1">{t`Your account has been activated`}</Typography>
      <p
        className={classes.instruction}
      >{t`Congratulations! Now you can start using Wealthstack WXAU.`}</p>

      <Button component={Link} componentProps={{ to: ROUTES.desktopPrivate.dashboard }}>
        {t`Let's Start`}
      </Button>
    </div>
  );
});

export default appWithStyles(styles)(Welcome);
