import React, { useCallback } from 'react';

import { appInject } from '@core/di/utils';
import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Link } from '@shared/components/link';
import {
  ToggleButton,
  ToggleTypeEnum,
} from '@shared/components/new-design/toggle-button/toggle-button';
import { DI_TOKENS } from '@shared/constants/di';
import { ROUTES } from '@shared/constants/routes';
import { Currency } from '@shared/models/wallets/currency';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { IRatesService } from '@shared/types/rates-service';
import { IRatesVM } from '@shared/types/rates-vm';
import { transformToBaseCurrency } from '@shared/utils/currency-pipe';

import { SettingsButton } from './components/settings-button';

import { styles } from './header.styles';

export interface HeaderProps extends AppWithStyles<typeof styles> {
  onSettings: () => void;
}

const HeaderComponent: React.FC<HeaderProps> = appObserver(({ classes, onSettings }) => {
  const ratesService = appInject<IRatesService>(DI_TOKENS.ratesService);
  const ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);

  const toggleOptions = ratesService.availableCurrencies.map((currency) => ({
    label: transformToBaseCurrency(currency),
    id: currency,
  }));
  const selectedOptionId = ratesVM.currency;

  const handleChangeCurrency = useCallback((newValue: string) => {
    ratesVM.currency = newValue as Currency;
  }, []);

  return (
    <div className={classes.header}>
      <Link to={ROUTES.desktopPrivate.dashboard} className={classes.logo}>
        {t`Dashboard`}
      </Link>

      <div className={classes.right}>
        <ul className={classes.rates}>
          <li>
            <span className={classes.value}>
              {minDigitsAfterDot(amountPipe(ratesVM.getHistoricalGrRate(), 2))}{' '}
            </span>
            <span>
              {ratesVM.currency}/{t`gr`}
            </span>
          </li>
          <li>
            <span className={classes.value}>
              {minDigitsAfterDot(amountPipe(ratesVM.getHistoricalOzRate(), 2))}{' '}
            </span>
            <span>
              {ratesVM.currency}/{t`oz`}
            </span>
          </li>
          <li>
            <span className={classes.value}>
              {minDigitsAfterDot(amountPipe(ratesVM.getHistoricalKgRate(), 2))}{' '}
            </span>
            <span>
              {ratesVM.currency}/{t`kg`}
            </span>
          </li>
        </ul>
        <div className={classes.switch}>
          <ToggleButton
            options={toggleOptions}
            selected={selectedOptionId}
            onChange={handleChangeCurrency}
            type={ToggleTypeEnum.PURPLE}
          />
        </div>
        <div className={classes.settings}>
          <SettingsButton onClick={onSettings} />
        </div>
      </div>
    </div>
  );
});

export const Header = appWithStyles(styles)(HeaderComponent);
