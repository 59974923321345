import React, { useCallback } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { NotificationListQuery } from '@shared/models/notifications/list-model';
import classNames from 'classnames';

import { styles } from './info-notification.styles';

export interface InfoNotificationProps extends AppWithStyles<typeof styles> {
  id: NotificationListQuery['id'];
  text: string;
  warning?: boolean;
  Link?: React.ReactElement;
  onClose?: (id: NotificationListQuery['id']) => void;
}

const InfoNotificationComponent: React.FC<InfoNotificationProps> = (props) => {
  const { classes } = props;
  const { warning, onClose, text, id, Link } = props;

  const _onClose = useCallback(() => {
    if (onClose) {
      onClose(id);
    }
  }, [id, onClose]);

  const isClosable = !!onClose;
  const className = classNames(classes.root, {
    [classes.warning as string]: warning,
    [classes.closable as string]: isClosable,
  });
  return (
    <div className={className}>
      <p>
        {text}
        {Link && ' '}
        {Link && Link}
      </p>
      {isClosable && (
        <span role="button" onClick={_onClose} className={classes.close}>
          {t`Close`}
        </span>
      )}
    </div>
  );
};

export const InfoNotification = appWithStyles(styles)(InfoNotificationComponent);
