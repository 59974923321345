import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles({ breakpoints, spacing }: AppTheme) {
  return appCreateStyles({
    root: {
      width: '100%',
      minHeight: 'inherit',
      background: _var(CSS_VARIABLES.backgroundEmptyColor),
    },
    limiter: {
      position: 'relative',
      maxWidth: _var(CSS_VARIABLES.dashboardWidth),
      minHeight: 'inherit',
      margin: '0 auto',
      transition: 'all 0.5s',
    },

    top: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: _var(CSS_VARIABLES.headerHeight),
    },
    logo: {},
    header: {
      height: '50px',
      background: _var(CSS_VARIABLES.backgroundEmptyColor),

      '& $logo': {
        display: 'block',
      },
    },

    notifications: {
      maxWidth: '635px',
      margin: '0 auto',
      padding: '10px 26px 0',

      '& > div': {
        marginBottom: '15px',
      },
      '& > div:last-child': {
        marginBottom: '0',
      },
    },

    content: {
      display: 'flex',
      minHeight: 'inherit',

      [breakpoints.down(Layout.mobile)]: {
        display: 'block',
      },
    },

    right: {
      overflow: 'hidden',
      minHeight: 'inherit',
      flexGrow: 1,
      background: '#1B112D',
      boxSizing: 'border-box',
      paddingTop: _var(CSS_VARIABLES.headerHeight),
      paddingBottom: spacing(4),
      [breakpoints.down(Layout.tablet)]: {
        paddingBottom: spacing(20),
      },
    },
  });
}
