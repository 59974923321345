import { appInject } from '@core/di/utils';
import { UserTypeEnum } from '@modules/private/onboarding/forms/user-type/user-type.enum';
import { DI_TOKENS } from '@shared/constants/di';
import { AvatarImageModel } from '@shared/models/users/avatar-image';
import { EmptyAvatarModel } from '@shared/models/users/empty-avatar';
import { IConfigService } from '@shared/types/config-service';
import { IUsersService } from '@shared/types/users-service';

export class OnboardingViewModel {
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private configService = appInject<IConfigService>(DI_TOKENS.configService);

  constructor() {}

  get mfaDelayMinutes() {
    return this.configService.mfaDelayMinutes;
  }

  getUserInfo = async () => {
    return await this.usersService.getMyUserDetails();
  };

  savePinCode = async (pinCode: string = '000000') => {
    return await this.usersService.savePinCode(pinCode);
  };

  savePhoneNumber = async (phoneNumber: string) => {
    return await this.usersService.savePhoneNumber(phoneNumber);
  };

  saveUserType = async (type: UserTypeEnum) => {
    return await this.usersService.saveUserType(type);
  };

  getTermsDocuments = async () => {
    return await this.usersService.getTermsDocuments();
  };

  saveApprovedDocuments = async (listOfIds: Array<string>) => {
    return await this.usersService.saveApprovedDocuments(listOfIds);
  };

  saveSystemName = async (systemName: string) => {
    return await this.usersService.saveSystemName(systemName);
  };

  getEmptyAvatar = async (): Promise<EmptyAvatarModel> => {
    return await this.usersService.getMyEmptyAvatar();
  };

  getAvatarsList = async (): Promise<Array<AvatarImageModel>> => {
    return await this.usersService.getAvatarsList();
  };

  saveAvatar = async (avatarId: string | null): Promise<void> => {
    return await this.usersService.saveAvatar(avatarId);
  };
}
