import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

import closeIcon from './img/close-icon.svg';
import warningIcon from './img/warning-icon.svg';

export function styles() {
  return appCreateStyles({
    root: {
      position: 'relative',
      padding: '5px 10px 6px',
      background: '#FFF9E5 no-repeat 10px center',
      borderRadius: '2px',
      color: '#AB860B',
      lineHeight: '17px',
      fontSize: '10px',
      fontWeight: 400,

      '& a': {
        color: _var(CSS_VARIABLES.accentColor),
        fontWeight: 500,
      },
    },
    closable: {
      paddingRight: '30px',
    },
    warning: {
      paddingLeft: '45px',
      backgroundImage: `url(${warningIcon})`,
      backgroundSize: 'auto 21px',
    },
    close: {
      overflow: 'hidden',
      position: 'absolute',
      top: '0',
      bottom: '0',
      right: '0',
      width: '30px',
      background: `url(${closeIcon}) no-repeat center center`,
      backgroundSize: 'auto 10px',
      cursor: 'pointer',
      textIndent: '-200%',
    },
  });
}
