import React, { useEffect, useState, KeyboardEvent } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import DocumentsButton from '@shared/components/new-design/document-button/documents-button';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { TermsDocumentModel } from '@shared/models/users/terms-documents-model';

import Document from './components/document/document';

import { styles } from './documents.styles';

export interface DocumentsFormProps extends AppWithStyles<typeof styles> {
  onSubmit: (data: Array<string>) => void;
  documentsList: Array<TermsDocumentModel>;
  defaultValue: Array<string>;
  resetValidation: () => void;
  error?: string;
  isLoading?: boolean;
}

const DocumentsFormComponent: React.FC<DocumentsFormProps> = ({
  onSubmit,
  classes,
  documentsList,
  defaultValue,
  resetValidation,
  error,
  isLoading,
}) => {
  const [activeDocument, setActiveDocument] = useState<string>('');

  const acceptedDocumentsMap = React.useMemo(() => new Map(), []);
  useEffect(() => {
    documentsList.forEach((doc) => {
      const isChecked = defaultValue.includes(doc.id);
      acceptedDocumentsMap.set(doc.id, isChecked);
    });
  }, []);

  const onAcceptDocument = (id: string) => {
    resetValidation && resetValidation();
    acceptedDocumentsMap.set(id, true);
    setActiveDocument('');
  };

  const isAcceptedAllDocuments = () => {
    if (acceptedDocumentsMap.size === 0) return false;
    return (
      Array.from(acceptedDocumentsMap.values()).filter((isAccepted) => !isAccepted).length === 0
    );
  };

  const documentsButtons = documentsList.map((document: TermsDocumentModel, index: number) => {
    return (
      <div key={index} className={classes.field}>
        <DocumentsButton
          label={document.title}
          number={index + 1}
          onClick={() => setActiveDocument(document.id)}
          isActive={acceptedDocumentsMap.get(document.id)}
        />
      </div>
    );
  });

  if (activeDocument) {
    const document = documentsList.find((doc) => doc.id === activeDocument);
    if (document) {
      return (
        <div className={classes.root}>
          <Document
            onAccept={onAcceptDocument}
            title={document.title}
            date={document.lastUpdateDate ? new Date(document.lastUpdateDate) : new Date()}
            url={document.url}
            id={document.id}
          />
        </div>
      );
    }
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' && isAcceptedAllDocuments()) {
      onSubmit(Array.from(acceptedDocumentsMap.keys()));
    }
  };

  return (
    <div className={classes.root} onKeyDown={handleKeyPress}>
      <div>
        <div className={classes.title}>{t`Documents`}</div>
        <div className={classes.description}>
          {t`To use Wealthstack please read and accept the following documents`}
        </div>
        <div className={classes.fields}>
          {documentsButtons}
          <div className={classes.error}>{error}</div>
        </div>
      </div>
      <SubmitButton
        label={t`Continue`}
        disabled={!isAcceptedAllDocuments()}
        isLoading={isLoading}
        onSubmit={() => onSubmit(Array.from(acceptedDocumentsMap.keys()))}
      />
    </div>
  );
};

export default appWithStyles(styles)(DocumentsFormComponent);
