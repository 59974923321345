import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './verification-topbar.styles';

export interface Props extends AppWithStyles<typeof styles> {
  isVisibleTopbar: boolean;
}

const VerificationTopbar: React.FC<Props> = ({ classes, isVisibleTopbar }) => {
  if (isVisibleTopbar) {
    return <div className={classes.root}></div>;
  } else {
    return <div></div>;
  }
};

export default appWithStyles(styles)(VerificationTopbar);
