import React, { KeyboardEvent } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { InputPhone } from '@shared/components/new-design/input-phone';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { nameOf } from '@shared/utils/nameof';
import { useForm } from 'react-hook-form';

import {
  IPhoneViewForm,
  PhoneViewFieldsResolver,
  PhoneViewFormFields,
} from './phone-number.validator';

import { styles } from './phone-number.styles';

export interface PhoneNumberFormProps extends AppWithStyles<typeof styles> {
  onSubmit: (email: string) => void;
  error?: string;
  defaultValue?: string;
  resetValidation?: () => void;
  isLoading?: boolean;
}

const PhoneNumberFormComponent: React.FC<PhoneNumberFormProps> = ({
  onSubmit,
  defaultValue,
  resetValidation,
  error,
  classes,
  isLoading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<PhoneViewFormFields>({ resolver: PhoneViewFieldsResolver });

  const onSubmitFrom = ({ phoneNumber }: IPhoneViewForm) => {
    onSubmit(phoneNumber);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter' && isValid) {
      handleSubmit(onSubmitFrom)();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitFrom)} onKeyDown={handleKeyPress} className={classes.root}>
      <div>
        <div className={classes.title}>{t`Your Phone Number`}</div>
        <div className={classes.description}>
          {t`Please your phone number to enable Two Factor Authentication. 2FA makes your account more secure.`}
        </div>
        <div className={classes.fields}>
          <div className={classes.field}>
            <InputPhone
              defaultValue={defaultValue || ''}
              controls={register(nameOf<IPhoneViewForm>('phoneNumber'))}
              resetValidation={resetValidation}
              helperText={error}
              error={Boolean(error)}
            />
          </div>
        </div>
      </div>
      <SubmitButton
        label={t`Continue`}
        disabled={!isValid}
        isLoading={isLoading}
        onSubmit={handleSubmit(onSubmitFrom)}
      />
    </form>
  );
};

export default appWithStyles(styles)(PhoneNumberFormComponent);
