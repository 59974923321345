import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { ImageListItem } from '@mui/material';

import EmptySvg from './img/ empty.svg';
import CheckIconSvg from './img/check.svg';

import { styles } from './empty-avatar.styles';

export interface EmptyAvatarProps extends AppWithStyles<typeof styles> {
  nameAbbreviation: string;
  color: string;
  isActive: boolean;
  onSelect: (nullData: null) => void;
}

const EmptyAvatarComponent: React.FC<EmptyAvatarProps> = ({
  classes,
  nameAbbreviation,
  color,
  isActive,
  onSelect,
}) => {
  return (
    <div className={isActive ? classes.root : `${classes.root} ${classes.pointer}`}>
      <ImageListItem onClick={(e) => onSelect(null)}>
        <img style={{ backgroundColor: color }} src={String(EmptySvg)} />
        <div className={classes.abbreviation}>{nameAbbreviation}</div>
        <div className={classes.label}>{t`No Avatar`}</div>
        {isActive ? (
          <div className={classes.checked}>
            {' '}
            <img src={String(CheckIconSvg)} />
          </div>
        ) : (
          <></>
        )}
      </ImageListItem>
    </div>
  );
};

export const EmptyAvatar = appWithStyles(styles)(EmptyAvatarComponent);
