import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import Carousel from 'react-bootstrap/Carousel';

import 'bootstrap/dist/css/bootstrap.min.css';
import Slide1 from './img/slide1-bag.svg';
import Slide2 from './img/slide2-calc.svg';
import Slide3 from './img/slide3-report.svg';
import Slide4 from './img/slide4-exchange.svg';
import Slide5 from './img/slide5-grow.svg';

import { styles } from './carousel.styles';

export interface OnboardingCarouselProps extends AppWithStyles<typeof styles> {}

const OnboardingCarouselComponent: React.FC<OnboardingCarouselProps> = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Carousel controls={false}>
        <Carousel.Item>
          <img src={String(Slide1)} />
          <Carousel.Caption>
            <h3>{t`Consistent growth`}</h3>
            <p>{t`Gold continues to provide consistent growth for long-term investors`}</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={String(Slide2)} />
          <Carousel.Caption>
            <h3>{t`Stable and Secure`}</h3>
            <p>{t`Gold remains a stable currency even during volatile times`}</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={String(Slide3)} />
          <Carousel.Caption>
            <h3>{t`Transact without borders`}</h3>
            <p>{t`Wealthstack gold tokens allow you to move your wealth across borders with little to no fees`}</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={String(Slide4)} />
          <Carousel.Caption>
            <h3>{t`Tangible value`}</h3>
            <p>{t`Wealthstack gold tokens represent real gold stored in high-security vaults around the globe`}</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={String(Slide5)} />
          <Carousel.Caption>
            <h3>{t`Access for all`}</h3>
            <p>{t`Gold plays a pivotal role in the financial monetary system. Wealthstack makes physical gold available to everyone`}</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default appWithStyles(styles)(OnboardingCarouselComponent);
