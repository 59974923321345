import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return appCreateStyles({
    root: {},
    instruction: {
      margin: '0 0 51px',
      fontWeight: 300,
      fontSize: '15px',
      lineHeight: '22px',
      color: '#212121',
    },
  });
}
