import React, { useCallback } from 'react';

import { appInject } from '@core/di/utils';
import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { OrderDetailsContainer } from '@modules/new-private/orders';
import { InfoNotification } from '@modules/private/layout/components/info-notification';
import { BackdropLoader } from '@shared/components/backdrop-loader';
import { Link } from '@shared/components/link';
import { useNavigate, Outlet } from '@shared/components/router';
import { DI_TOKENS } from '@shared/constants/di';
import { ROUTES } from '@shared/constants/routes';
import { NotificationType } from '@shared/models/notifications/notification-type';
import { ILayoutVM } from '@shared/types/layout-service';

import { Header } from './components/header';
import { Sidebar } from './components/sidebar';

import { styles } from './private-layout.styles';

export interface DashboardProps extends AppWithStyles<typeof styles> {}

const PrivateLayout: React.FC<DashboardProps> = appObserver(({ classes }) => {
  const layoutVM = appInject<ILayoutVM>(DI_TOKENS.layoutVM);
  const navigate = useNavigate();
  const handleSettings = useCallback(() => navigate(ROUTES.desktopPrivate.settings.root), []);

  return (
    <div className={classes.root}>
      <BackdropLoader open={layoutVM.isGlobalLoadingShown} />
      <div className={classes.limiter}>
        <div className={classes.top}>
          <Header onSettings={handleSettings} />
        </div>

        <div className={classes.content}>
          <Sidebar />
          <div className={classes.right}>
            {layoutVM.hasNotifications && (
              <div className={classes.notifications}>
                {layoutVM.notifications.map((n) => {
                  const { id, type, actionPath, actionText, closable, text, persistent } = n.asJson;
                  const isWarning = type === NotificationType.Warning;

                  return (
                    <InfoNotification
                      key={id}
                      id={id}
                      text={text}
                      onClose={
                        closable && persistent
                          ? layoutVM.hideNotification
                          : closable
                          ? layoutVM.removeNotification
                          : undefined
                      }
                      Link={
                        actionPath && actionText ? (
                          <Link to={actionPath}>{actionText}</Link>
                        ) : undefined
                      }
                      warning={isWarning}
                    />
                  );
                })}
              </div>
            )}
            <Outlet />
          </div>
          <OrderDetailsContainer />
        </div>
      </div>
    </div>
  );
});

export default appWithStyles(styles)(PrivateLayout);
