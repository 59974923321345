import React, { useEffect } from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';

import WelcthstackSVG from './img/wealthsteck.svg';

import { styles } from './welcome-screen.styles';
export interface WelcomeScreenProps extends AppWithStyles<typeof styles> {}

const WelcomeScreenComponent: React.FC<WelcomeScreenProps> = ({ classes }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(ROUTES.mobilePrivate.dashboard);
    }, 3000);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <div className={classes.title}>{t`Welcome to`}</div>
        <div className={classes.wealthstack}>
          <img className={classes.wealthstackImage} src={String(WelcthstackSVG)}></img>
        </div>
      </div>
    </div>
  );
};

export default appWithStyles(styles)(WelcomeScreenComponent);
